<template>
    <div class="content">
        <div class="search">
          <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="道闸事件" :fetch="createExportData" :before-finish="finishDownload" worksheet="道闸事件">
              <el-button type="primary" class="el-icon-download"> 导出</el-button>
          </JsonExcel>
          <el-button type="primary" class="el-icon-upload2" @click='refreshUpload' style="margin-left: 10px;"> 重新上传</el-button>
        </div>
        <el-card style="margin-top:15px;width:100%" class="box-card">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>时间：</label>
                 <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>通道：</label>
                <el-select v-model="searchForm.gt_id" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in GateDataList" :key="item.gt_id" :label="item.gt_name" :value="item.gt_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>操作员：</label>
                <el-select v-model="searchForm.user_id" style="width:150px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option v-for="item in UserDataList" :key="item.user_id" :label="item.user_name" :value="item.user_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <div class="search">
                  <label>事件：</label>
                  <el-select v-model="searchForm.event_type" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="线控开闸" :value="1"></el-option>
                    <el-option label="遥控开闸" :value="2"></el-option>
                    <el-option label="地感、雷达防砸开闸" :value="3"></el-option>
                    <el-option label="线控关闸" :value="4"></el-option>
                    <el-option label="遥控关闸" :value="5"></el-option>
                    <el-option label="常抬超时自动关闸" :value="6"></el-option>
                    <el-option label="线控停止" :value="7"></el-option>
                    <el-option label="遥控停止" :value="8"></el-option>
                    <el-option label="遇阻开闸" :value="9"></el-option>
                  </el-select>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div v-if="User.user_type!==5" class="search">
                <label>车场：</label>
                <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                  <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                  <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="2">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>

          <el-table :data='DataList' border style="width: 100%;font-size:13px" max-height='calc(100vh - 250px)' height='calc(100vh - 250px)' stripe :highlight-current-row='true' size="mini" @expand-change="expandChanged">
            <el-table-column type="expand">
              <template slot-scope="slot">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div>
                      <span style="margin-left:30px;font-size:15px">抓拍图片：</span>
                      <el-image v-if="slot.row.picdata.length>0" style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px"  :src='slot.row.picdata[0]' fit="fill" @click="ImgClick"></el-image>
                      <el-dialog title="抓拍图片：" :visible.sync="imgDialogVisible" :append-to-body='true'>
                          <img width="100%" :src="slot.row.picdata[0]" alt="">
                      </el-dialog>
                    </div>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场"></el-table-column>
            <el-table-column align="center" prop="log_time" label="时间" min-width="145px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_time)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="gt_name" label="通道"></el-table-column>
            <el-table-column align="center" prop="user_name" label="操作员"></el-table-column>
            <el-table-column align="center" prop="event_info" label="事件"></el-table-column>
          </el-table>
          <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @size-change="handleSizeChange" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
  
    </div>
</template>

<script>
import util from '../../../util/util'
import JsonExcel from 'vue-json-excel'

export default{
    data:function(){
        return{
          User:null,
            searchForm:{
              pk_id:0,
                pageNo:1,
                pageSize:15,
                total:0,
                time:[],
                gt_id:0,
                user_id:0,
                event_type:0
            },
            imgDialogVisible:false,
            GateDataList:[],
            UserDataList:[],
            DataList:[],
            ParkDataList:[],

            exportDataStandard: {
              所属车场: "park_name" ,
              时间: {
                field: 'log_time',
                callback: value => {
                  return this.FormData(value)
                }
              },
              通道: "gt_name" ,
              入场岗亭: "post_name" ,
              操作员: "user_name" ,
              事件:'event_info'
            },
            exportData: []
        }
    },
    computed:{
        FormData () {
          return function (time) {
            if(time===null||time===undefined||time==='')
              return ''
            else
              return util.FormateDate(time, 2)
          }
        },
    },
    components: {
      JsonExcel
    },
    created(){
      this.User = util.JesToken()
        this.getParkDataList()
    },
    methods:{   
      getParkDataList(){
        if(this.User.user_type===5){
            this.GetDataList()
            this.GetUserDataList()
            this.GetGateDataList()
        }
        else{
          util.Get('parkmanage/getparkdatalist').then(res => {
            if (res.rpStatus === 10000) {
               this.ParkDataList = res.list
               if(res.list.length>0){
                 this.searchForm.pk_id=res.list[0].pk_id
                 this.GetDataList()
                 this.GetUserDataList()
               }
            }
          })
        }
      },
      GetUserDataList(){
            util.Get('user/getuserdataList?id='+this.searchForm.pk_id+'&userType=5').then(res=>{
                if(res.rpStatus===10000)
                    this.UserDataList=res.list
            })
      },
      GetGateDataList(){
        util.Get("gate/getgatedatalist?id="+this.searchForm.pk_id).then(res=>{
          if(res.rpStatus===10000){
            if(this.User.user_type===5){
              this.GateDataList=res.list[4].filter(a=>a.gt_id!==0)
            }
            else
              this.GateDataList=res.list[0]
          }
        })
      },
      parkChanged(value){
        this.searchForm.pk_id=value
        this.searchForm.card_id=0
        this.GetDataList()
        this.searchForm.user_id=0
        this.GetUserDataList()
        this.GetGateDataList()
      },
 
        GetDataList(){
            this.searchForm.begintime=''
            this.searchForm.endtime=''
            if(this.searchForm.time!=undefined&&this.searchForm.time!==null&&this.searchForm.time.length>0){
                this.searchForm.begintime=this.searchForm.time[0]
                this.searchForm.endtime=this.searchForm.time[1]
            }

            util.Get('loggateevent/getlogdataList',this.searchForm).then(res=>{
                if(res.rpStatus===10000){
                    this.searchForm.total=res.total
                    res.list.forEach(element => {
                        element.picdata = []
                    })
                    this.DataList=res.list
                   
                }
            })
        },
        current_change(page){
            this.searchForm.pageNo=page
            this.GetDataList()
        },
        handleSizeChange(pageSize){
            this.searchForm.pageSize=pageSize
            this.GetDataList()
        },
        Search(){
            this.GetDataList()
        },
        expandChanged(row, expandedRows){
            var arr = expandedRows.filter(a => a.log_id === row.log_id)
            if (arr.length > 0) {
                // 展开行
                if (row.picdata.length === 0 && row.log_pic_name !== null && row.log_pic_name !== undefined && row.log_pic_name !== '') {
                        util.Get('picture/getparkpictrue?parkId='+row.park_id+'&logId='+row.log_id+'&picInName=' + row.log_pic_name).then(res=>{
                            if(res.rpStatus===10000){
                                var data=JSON.parse(res.list[0])
                                var log=row
                                if(row.park_id!==data.parkId||row.log_id!==data.logId){
                                var arr=this.DataList.filter(a=>a.park_id===data.parkId&&a.log_id==data.logId)
                                if(arr.length>0)
                                    log=arr[0]
                                }
                                console.log(data)
                                this.$set(log, 'picdata', [data.in_pic,data.out_pic])
                            }
                            else{
                            this.$message.error(res.rpMsg)
                            }
                        })
                }
            }
        },
        ImgClick () {
            this.imgDialogVisible = true
        },

        refreshUpload(){
          util.Send('refresh_upload_gateEvent',this.searchForm.pk_id)
        },
        // 导出
        async createExportData () {
          this.searchForm.pageNo = 0
          var form = JSON.parse(JSON.stringify(this.searchForm))
          form.pageNo = 0

          var result = await util.Get('loggateevent/getlogdataList', form)
          if (result.rpStatus === 10000) {
            this.exportData = result.list
            return this.exportData
          }
        },
        finishDownload () {
          this.$message({
            message: '导出数据成功',
            type: 'success'
          })
          this.exportData=[]
        }
    }
}
</script>

<style scoped>
  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.el-table{
        margin-top: 15px;
    }
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
::v-deep.el-table thead {
  color: #666666;
  font-weight: 500;
}
</style>